.InfoContainer {
  width: 80%;
  height: 55vh;
  min-height: 400px;
  background-color: rgba(20, 66, 99, .8);
  color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.InfoTitle {
  height: 15%;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(25px + 2vmin);
}

  .Info-Phone-Number {
    font-size: 28px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Info-Help-Title {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Info-google-review {
    font-size: 26px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e66909;
    text-decoration: none;
    transition: all ease-in-out .2s;
  }

  .Info-google-review:hover {
    color: #f4f4f4;
  }

.InfoPanelContainer {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InfoPanelLeft {
  height: 98%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.InfoPanelRight {
  height: 98%;
  width: 100%;
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-left: 1px solid #bd5200;
}

.PanelTitle {
  width: 100%;
  height: 15%;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

  .PanelTitleWording {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PanelSeeAllLink {
    width: 30%;
    color: #f4f4f4;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 22px;
    justify-content: flex-start;
    transition: all ease-in-out .2s;
  }

    .PanelSeeAllLink:hover {
      color: #e66909;
    }


.PanelLinks {
  width: 100%;
  height:  100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.PanelDescription {
  width: 90%;
  height: 97.5%;
  padding-left: 5%;
  padding-top: 2.5%;
}

.HelpLink {
  width: 100%;
  color: #e66909;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
  text-align: left;
  font-size: calc(10px + 2vmin);
  transition: all ease-in-out .2s;
}

.HelpLink::before {
  content: "› ";
}

.HelpLink:hover {
  opacity: .8;
  z-index: 0;
}

@media screen and (max-width: 1400px) {
  .Info-Phone-Number {
    font-size: calc(6px + 2vmin);
  }

  .Info-google-review {
    font-size: calc(6px + 2vmin);    
  }

  .Info-Help-Title {
    font-size: calc(4px + 3.5vmin);
  }

  .HelpLink {
    padding-top: 5px;
    font-size: calc(6px + 2vmin);
  }

  .PanelDescription {
    font-size: calc(6px + 2vmin);
  }

  .PanelSeeAllLink {
    font-size: calc(6px + 1.5vmin);    
  }
}