.Header {
  grid-column: 1;
  grid-row: 1;
  z-index: 3;
  min-width: 825px;
  width: 95%;
  height: 100%;
  min-height: 75px;
  background-color: rgba(20, 66, 99, .9);
  display: flex;
  justify-content: flex-start;
  border-bottom: 2px solid #bd5200;
  align-items: flex-start;
  padding-left: 5%;
  font-size: calc(25px + 2vmin);
  color: #f2f2f2;
}

.CompanyTitle {
  height: 90%;
  display: flex;
  align-items: flex-end;
  font-size: calc(34px + 2vmin);
}

.TitleDescription {
  height: 82%;
  display: flex;
  align-items: flex-end;
  font-size: calc(15px + 2vmin);
  padding-left: 15px;
}