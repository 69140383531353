.App {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows:75px calc(100vh - 75px);
}

body {  
  background-color: #48535c;
  font-size: calc(10px + 2vmin);
}

.Background {
  grid-column: 1;
  grid-row: 1 / 3;
  width: 100%;
  height: 100%;
  filter: blur(8px);
  -webkit-filter: blur(5px);
  z-index: 1;  
  min-height: 800px;
  min-width: 825px;
}

.BodyContentContainer {
  grid-column: 1;
  grid-row: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  min-width: 825px;
}