.DownloadCenter {
    background-color: rgba(28, 28, 28, .7);
    width: 78%;
    height:  25vh;
    min-width: 800px;
    min-height: 200px;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

    .DownloadBarInfo {
      height: 75px;
      width: 100%;
      font-size: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 1vh;
      color: #f2f2f2;
    }

  .DropContainer {
    width: 775px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .DropContentContainer {
      width: 80%;
      height: 100%;
      color: #333;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #f2f2f2;
      border-left: 4px solid #144263;
      border-top: 4px solid #144263;
      border-bottom: 4px solid #144263;
      transition: all .15s ease-in-out;
    }

    .DropContentContainer:hover {
      cursor: pointer;
      border-left: 4px solid #19537d;
      border-top: 4px solid #19537d;
      border-bottom: 4px solid #19537d;
      filter: drop-shadow(.5px .5px 7.5px #f2f2f260);
    }

    .EnterButton {
      width: 20%;
      height: 100%;
      color: #333;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #bd5200;
      border: 4px solid #8a4700;
      transition: all .15s ease-in-out;
      color: #f2f2f2;
    }

    .EnterButton:hover {
      cursor: pointer;
      color: #e6e6e6;
      filter: drop-shadow(2.5px 2.5px 10px #e6630060);
    }

    .ErrorMsg {
      position: absolute;
      top: calc(100vh - 90vh + 20px);
      z-index: 6;
    }

    .SuccessMsg {
      position: absolute;
      top: 95px;
      z-index: 5;
    }

@media screen and (max-width: 1400px) {

}

.SelectionField {
  width: 80%;
  height: 100%;
  padding-left: 5%;
  color: #333;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f2f2f2;
  border-left: 4px solid #144263;
  border-top: 4px solid #144263;
  border-bottom: 4px solid #144263;
  transition: all .15s ease-in-out;
  font-size: 24px;
}

.SelectionField:hover {
  cursor: pointer;
  border-left: 4px solid #19537d;
  border-top: 4px solid #19537d;
  border-bottom: 4px solid #19537d;
  filter: drop-shadow(.5px .5px 7.5px #f2f2f260);
}

.DropDownMenu {
  position: absolute;
  margin-top: 425px;
  margin-right: 160px;
  width: 600px;
  height: 350px;
  background-color: white;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  z-index: 1;
}

.DropDownClosed {
  display: none;
}

.MenuOption {
  width: 95%;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
}

.MenuOption:hover {
  cursor: pointer;
  background-color: #33333309;
}

.SelectionImg img {
  width: 25px;
  height: 100%;
  font-size: 12px;
  padding-right: 10px;
}